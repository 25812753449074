<template>
<div>
<validation-observer ref="answerQuestionForm">
    <b-form>
        <b-card no-body>
            <b-card-header>تفاصيل السؤال</b-card-header>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="4">
                        <b-form-group label="اسم الطالب" label-for="studentName">
                            <b-form-input
                                type="text"
                                id="studentName"
                                readonly
                                v-model="questionDto.studentName"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group label="اسم المادة" label-for="subjectName">
                            <b-form-input
                                type="text"
                                id="subjectName"
                                readonly
                                v-model="questionDto.subjectName"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group label="تاريخ الإرسال" label-for="sendDate">
                            <b-form-input
                                type="text"
                                id="sendDate"
                                readonly
                                :value="new Date(questionDto.dateAsked).toISOString().substr(0, 19).replace('T',' ')"
                            />
                            
                        </b-form-group>
                    </b-col>
                    
                    <b-col cols="12" md="6" class="mt-2">
                        <b-form-group label="السؤال" label-for="question">
                            <b-form-textarea
                                disabled
                                id="question"
                                rows="8"
                                :value="questionDto.question"
                            />
                        </b-form-group>
                    </b-col>
                    
                    <b-col cols="12" md="6" class="mt-2">
                        <b-form-group label="الجواب" label-for="answer">
                            <!-- <div class="position-absolute d-flex justify-content-end align-items-center" style="left: 12px; top: -6px;">
                                <small style="padding-left: 6px;">نص</small>
                                <b-form-checkbox
                                    class="custom-control-primary"
                                    v-model="questionDto.answerType"
                                    switch
                                >
                                    <span class="switch-icon-left">
                                        <unicon name="video" fill="#fff"></unicon>
                                    </span>
                                    <span class="switch-icon-right">
                                        <unicon name="text" fill="#5E5873"></unicon>
                                    </span>
                                </b-form-checkbox>
                                <small>فيديو</small>
                            </div> -->
                            <validation-provider
                                v-if="!questionDto.answerType"
                                #default="{ errors }"
                                name="الجواب"
                                rules="required"
                            >
                                <b-form-textarea
                                    id="answer"
                                    :state="errors.length > 0 ? false : null"
                                    rows="8"
                                    v-model="questionDto.answer"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <div v-else>
                                <fileDragDrop @inValidFile="inValidVideo" @uploaded="upload" type="mp4" title="تحميل فيديو"/>
                                <video ref="videoPlayer" type="video/mp4" style="max-height: 320px" class="w-100" controls v-if="true">
                                </video>
                                <img v-else :src="$store.getters['app/placeHoldervideo']" class="w-100">
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer class="border-0 pt-0">
                <b-button variant="primary" class="mr-1" @click="answerQuestionForm">إرسال</b-button>
                <b-button variant="outline-primary" @click="backHome">تراجع</b-button>
            </b-card-footer>
        </b-card>
    </b-form>
</validation-observer>
</div>
</template>
<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
import { getHomeRouteForResponderUser, getUserData } from "@/auth/utils";
import {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCardFooter,
    BButton,
    BForm
    // BFormCheckbox
} from "bootstrap-vue"
import { mapGetters, mapActions } from 'vuex'
import { required } from '@validations';

export default {
    props: {
        id: String,
        quesId: String,
        videoId: String
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        fileDragDrop,
        BCard,
        BCardHeader,
        BCardBody,
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BCardFooter,
        BButton,
        BForm
        // BFormCheckbox
    },
    data: () => ({
        required
    }),
    computed: {
        ...mapGetters(['questionDto'])
    },
    mounted() {
        localize('ar')
        this.getVideoQuestionDetails(this.quesId)
    },
    methods: {
        ...mapActions(['getVideoQuestionDetails', 'postQuestionAnswer']),
        upload(file) {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = (() => {
                this.$refs.videoPlayer.src = reader.result
            }).bind(this)
        },
        inValidVideo(){

        },
        answerQuestionForm() {
            this.$refs.answerQuestionForm.validate()
            .then(success => {
                 if(success) {
                    this.postQuestionAnswer(this.questionDto)
                }
            })
        },
        backHome() {
           this.$router.push(getHomeRouteForResponderUser(getUserData().role ,this.id, this.videoId, 0))
        }
    }
}
</script>